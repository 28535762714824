.animating-scroll {
  position: relative;
  .trigger {
    height: 20%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    margin: auto;
  }
}
.banner-home {
  position: relative;
  .wrap-video {
    height: 100vh;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba($color: #191919, $alpha: .5);
    }
    video {
      height: 100%;
      object-fit: cover;
    }
    figure {
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  &__caption {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    h2 {
      font-size: 60px;
      font-weight: 600;
      line-height: 1.2;
      position: relative;
      opacity: 0;
      left: -50px;
    }
    p {
      font-size: 20px;
      margin: 0;
      position: relative;
      opacity: 0;
      top: 50px;
    }
    .text {
      width: 590px;
      color: var(--color-initial);
    }
  }
  &.add-animation {
    .banner-home__caption {
      h2 {
        left: 0;
        opacity: 1;
        transition: all ease-out .45s;
        transition-delay: .2s;
      }
      p {
        top: 0;
        opacity: 1;
        transition: all ease-out .45s;
        transition-delay: .3s;
      }
    }
  }
}


.info-company {
  &__box {
    margin: 0 60px;
    background: rgb(114,150,81);
    background: linear-gradient(180deg, rgba(114,150,81,1) 0%, rgba(67,96,40,1) 100%);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 60px;
    justify-content: center;
    position: relative;
    opacity: 0;
    &--item {
      position: relative;
      padding: 0 52px;
      text-align: center;
      &:after {
        content: '';
        position: absolute;
        height: 80%;
        top: 0;
        bottom: 0;
        right: 0;
        background: #5DC662;
        left: auto;
        margin: auto;
        width: 1px;
        opacity: .8;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .count {
        font-size: 45px;
        font-weight: 500;
        color: var(--color-initial);
        min-width: 160px;
        padding-bottom: 8px;
        border-bottom: 2px solid var(--color-initial);
        line-height: 1.1;
        margin-bottom: 16px;
      }
      > span {
        display: block;
        font-size: 20px;
        color: var(--color-initial);
        i {
          font-style: normal;
        }
      }
    }
  }
  &.after-animate {
    .info-company__box {
      margin-top: -100px;
      transition: all ease-out .45s;
      transition-delay: .2s;
      opacity: 1;
    }
  }
}

.heritage-home {
  overflow: hidden;
  position: relative;
  .slide-info-text {
    padding-top: 80px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    .item-text {
      flex-wrap: wrap;
      //display: flex !important;
      justify-content: space-between;
      display: flex !important;
      &.first {
        display: flex;
        &.act {
          display: none;
        }
      }
      &.last {
        &.act {
          display: flex;
        }
      }
      &.active {
        display: flex !important
  
      }
      &__desc {
        width: 800px;
        &.full {
          width: 100%;
        }
        h6,h3,p {
          position: relative;
          opacity: 0;
          left: -50px;
        }
        h6 {
          font-size: 17px;
          color: var(--color-secondary);
          font-style: italic;
          
        }
        h3 {
          color: var(--color-neutral);
          margin-bottom: 16px;
          font-weight: 300;
        }
        p {
          font-size: 20px;
          small {
            font-size: 15px;
          }
        }
      }
      &__years {
        width: auto;
        color: var(--color-neutral);
        font-size: 150px;
        opacity: 0;
        font-weight: 800;
        font-style: italic;
        top: -50px;
        position: absolute;
        white-space: nowrap;
        left: auto;
        right: 0;
        padding-right: 16px;
        span {
          line-height: 1;
          position: relative;
          display: block;
          margin-bottom: -30px;
          font-size: 60px;
        }
      }
    }
    .slick-active {
      .item-text {
        &__desc {
          h6, h3,p {
            transition: all ease-out .4s;
            left: 0;
            opacity: 1;
          }

          h6 {
            transition-delay: .2s;
          }

          h3 {
            transition-delay: .3s;
          }

          p {
            transition-delay: .4s;
          }
        }
      }

      .item-text__years {
        opacity: .08;
        transition: all ease-out .4s;
        transition-delay: .5s;
        top: 0;
      }
    }
  }
  .inner-slider-heritage {
    position: relative;
    //height: 100vh;
    .slide-info-image {
      width: auto;
      margin-right: calc((100vw - var(--wrap)) / 2 * -1);
      padding-top: 0;
      .list-heritage {
        display: flex;
        position: relative;
        height: auto;
        flex-wrap: nowrap;
        width: 100%;
        padding-top: 0px;
        .slick-slide {
          margin-right: 36px;
          width: 940px;
        }
        figure {
          height:50vh;
          width: 940px;
          display: block;
          margin-right: 60px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
          span {
            display: block;
            margin-top: 12px;
            font-style: italic;
          }
        }
      }
    }
    .progress-indicator {
      height: 5px;
      display: block;
      width: 100%;
      margin-top: 32px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        margin: auto;
        background: #ccc;
        z-index: -1;
      }
      #progress {
        width: 0;
        display: block;
        height: 5px;
        background: #3E9942;
        border-radius: 2px;
        transition: all ease-out .5s;
      }
    }
    .slick-arrow {
      position: absolute;
      z-index: 3;
      width: 44px;
      height: 44px;
      background: #fff url(../images/material/arrow-slide.png) no-repeat center;
      padding: 0;
      border: none;
      border-radius: 22px;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 0;
      right: 60px;
      left: auto;
      &.slick-prev {
        left: -60px;
        right: auto;
        transform: rotate(180deg);
      }
      &.slick-disabled {
        opacity: .4;
      }
    }
  }
  &.animating-scroll {
    .slide-info-text {
      left: 100px;
      opacity: 0;
      transition: all ease-out .4s;
    }
    .inner-slider-heritage {
      .slide-info-image {
        .list-heritage {
          figure {
            left: 80px;
            opacity: 0;
          }
        }
      }
    }
  }
  &.after-animate {
    .slide-info-text {
      left: 0;
      opacity: 1;
      transition: all ease-out .4s;
      transition-delay: .35s;
    }
    .inner-slider-heritage {
      .slide-info-image {
        .list-heritage {
          figure {
            transition: all ease-out .45s;
            left: 0;
            opacity: 1;
            &:nth-child(1) {
              transition-delay: .6s;
            }
            &:nth-child(2) {
              transition-delay: .7s;
            }
            &:nth-child(3) {
              transition-delay: .8s;
            }
            &:nth-child(4) {
              transition-delay: .6s;
            }
            &:nth-child(5) {
              transition-delay: .7s;
            }
          }
        }
      }
    }
  }
  
}


.product-home {
  z-index: 3;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0;
    min-height: 100vh;
  }
  &.after-animate {
    .product-home__text {
      h3,.wrap-button {
        opacity: 1;
        left: 0;
        transition: all ease-out .45s;
      }
      h3 {
        transition-delay: .4s;
      }
      .wrap-button {
        transition-delay: .5s;
      }
    }
    .image-big {
      opacity: 1;
      transition-delay: .45s;
      transition: transform ease-out .45s;
      transform: scale(1);
    }
    .image-thumb__item {
      transition: opacity ease-out .45s;
      opacity: 1;
      &:nth-child(1) {
        transition-delay: .5s;
      }
      &:nth-child(2) {
        transition-delay: .55s;
      }
      &:nth-child(3) {
        transition-delay: .6s;
      }
    }
  }
  &__text {
    width: 350px;
    h3,
    .wrap-button  {
      position: relative;
      opacity: 0;
      left: -50px;
    }
  }
  .image-big {
    position: absolute;
    width: 588px;
    opacity: 0;
    transform: scale(1);
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    &__img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: rotate(-10deg) scale(.5);
      transition: all ease-out .5s;
      
      img {
        display: block;
      }
      &.active {
        opacity: 1;
        position: relative;
        visibility: visible;
        transform: rotate(0deg) scale(1);
        transition: all ease-out .5s;
      }
    }
  }
  .image-thumb {
    width: 240px;
    margin-left: 110px;
    display: flex;
    flex-direction: column;
    position: relative;    
    &:before,
    &:after {
      content: '';
      height: 80%;
      position: absolute;
      left: -200px;
      right: 70px;
      top: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      border: 2px dashed #E6E6E6;
      border-radius: 100%;
      border-left: none;
    }
    &:after {
      z-index: 1;
      background: #fff;
      border-radius: 0;
      border: none;
      width: 67%;
      right: auto;
    }
    &__item {
      width: 155px;
      margin-left: -60px;
      margin-bottom: 40px;
      cursor: pointer;
      text-align: center;
      position: relative;
      z-index: 2;
      opacity: 0;
      .bg-image {
        width: 100%;
        padding: 20px;
        position: relative;
        border-radius: 50%;
        transition: all ease-out .25s;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 50%;
          border: 2px dashed var(--color-primary);
          top: 0;
          left: 0;
          transform: scale(.72);
          opacity: 0;
          transition: all ease-out .25s;
        }
        figure {
          border: 12px solid #E6E6E6;
          border-radius: 50%;
          overflow: hidden;
          transition: all ease-out .25s;
          img {
            display: block;
          }
        }
      }
      span {
        font-size: 17px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: -15px;
        transition: all ease-out .25s;
        display: block;
        position: absolute;
        bottom: -10px;
        top: auto;
        left: 0;
        right: 0;
        text-align: center;
        padding-top: 10px;
      }
      &.active {
        .bg-image {
          transform: scale(1);
          background: var(--color-initial);
          transition: all ease-out .25s;
          &:after {
            transform: scale(1);
            opacity: 1;
            transition: all ease-out .25s;
          }
          figure {
            border-color: #CEE3B0;
            transition: all ease-out .25s;
          }
        }
        span {
          bottom: -30px;
          transition: all ease-out .25s;
          color: var(--color-primary);
        }
      }
      &:nth-child(2n) {
        margin-left: auto;
        text-align: left;
        span {
          text-align: left;
        }
      }
      &:last-child{
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }
}

.comitment-home {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 72px);
  background: rgb(180,0,0);
  background: linear-gradient(184deg, rgba(180,0,0,1) 57%, rgba(43,4,7,0.9248074229691877) 100%);
  z-index: 2;
  .half-video {
    width: 50%;
    min-height: 500px;
    figure {
      position: relative;
      height: 100%;
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    video {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .half-text {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    overflow: hidden;
    &__information {
      width: 380px;
      margin-right: calc((100vw - var(--wrap)) / 2);
      color: var(--color-initial);
      h4,p,.wrap-button {
        position: relative;
        left: 50px;
        opacity: 0;
      }
      h4 {
        font-size: 27px;
        font-weight: 600;
      }
      p {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }
  }
  &.after-animate {
    .half-text__information {
      h4,p,.wrap-button {
        left: 0px;
        opacity: 1;
        transition: all ease-out .45s;
      }
      h4 {
        transition-delay: .4s;
      }
      p {
        transition-delay: .5s;
      }
      .wrap-button {
        transition-delay: .6s;
      }
    }
  }
}

.home-area {
  background: rgb(67,96,40);
  background: linear-gradient(0deg, rgba(67,96,40,1) 0%, rgba(114,150,81,1) 100%);
  padding: 120px 0 270px;
  margin-bottom: -258px;
  overflow: hidden;
  &.animating-scroll{
    .text {
      h3,p {
        position: relative;
        opacity: 0;
        top: 40px;
      }
    }
    .globe-wrap {
      opacity: 0;
      transform: scale(.8);
    }
    &.after-animate {
      .text {
        h3,p {
          opacity: 1;
          transition: all ease-out .45s;
          top: 0;
        }
        h3 {
          transition-delay: .35s;
        }
        p {
          transition-delay: .4s;
        }
      }
      .globe-wrap {
        transition: all ease-out .45s;
        opacity: 1;
        transform: scale(1);
        transition-delay: .65s;
      }
    }
  }
  
  .text {
    text-align: center;
    width: 820px;
    margin: 0 auto 150px;
    color: var(--color-initial);
    
    h3 {
      font-weight: 300;
    }
    p {
      font-size: 18px;
    }
  }
  .globe-wrap {
    position: relative;
    figure {
      height: 740px;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        object-position: top;
      }
      &.mobile-globe {
        display: none;
      }
    }
    .list-countries {
      display: none;
    }
  }
  .pin-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: var(--wrap);
    margin: auto;
    bottom: 0;
    .pin {
      width: 30px;
      height: 30px;
      position: absolute;
      &__dot {
        width: 20px;
        height: 14px;
        border: 2px solid var(--color-initial);
        display: block;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        z-index: 3;
        margin: 0 auto;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          background: var(--color-initial);
          position: absolute;
          border-radius: 50%;
          top: -1px;
          bottom: auto;
          left: 0;
          right: 0;
          margin: auto;
          transform: scale(.5);
        }
      }
      &__desc {
        min-width: 240px;
        position: absolute;
        padding-bottom: 90px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        &:before {
          content: '';
          height: calc(100% - 70px);
          left: 0;
          right: 0;
          margin: auto;
          width: 1px;
          background: var(--color-initial);
          position: absolute;
          bottom: 30px;
        }
        &--title {
          display: block;
          padding: 10px;
          border-radius: 10px;
          background: var(--color-initial);
          font-size: 17px;
          text-align: center;
        }
        .area-coverage {
          width: 100%;
          background: #527235;
          padding: 8px;
          border-radius: 10px;
          color: var(--color-initial);
          position: absolute;
          top: 60px;
          left: 70%;
          font-size: 15px;
          opacity: 0;
          &.lf {
            left: auto;
            right: 70%;
          }
        }
      }
      &.active {
        z-index: 9;
        .pin__desc {
          opacity: 1;
          visibility: visible;
          opacity: 1;
          padding-bottom: 200px;
          transition: all ease-out .5s;
          .area-coverage {
            opacity: 1;
            transition-delay: .2s;
          }
        }
      }
    }
  }
}

.contact-home {
  position: relative;
  margin-bottom: -240px;
  z-index: 3;
  &.animating-scroll {
    figure {
      img {
        transform: scale(1.15);
        opacity: 1;
        position: relative;
      }
    }
    .contact-home__text {
      h3,.wrap-button {
        position: relative;
        opacity: 0;
        top: 30px;
      }
    }
    &.after-animate {
      figure {
        img {
          transition: all ease-out .45s;
          opacity: 1;
          transform: scale(1);
          transition-delay: .3s;
        }
      }
      .contact-home__text {
        h3,.wrap-button {
          opacity: 1;
          top: 0;
          transition: all ease-out .45s;
        }
        h3 {
          transition-delay: .4s;
        }
        .wrap-button {
          transition-delay: .5s;
        }
      }
    }
  }
  figure {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($color: #1f1f1f, $alpha: .6);
    }
    img {
      display: block;
    }
  }
  &__text {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    width: 720px;
    margin: 0 auto;
    text-align: center;
    color: var(--color-initial);
    h3 {
      font-weight: 300;
      margin-bottom: 24px;
    }
    .button {
      color: var(--color-initial);
      background: rgba($color: #ffffff, $alpha: .18);
    }
  }
  &.with-bg {
    position: relative;
    &:before {
      content: '';
      height: 52%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      position: absolute;
      background: #f5f5f5;
    }
  }
}



/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  
  
  .info-company {
    &__box {
      margin: 0;
      padding: 40px;
      &--item {
        padding: 0 44px;
        .count {
          font-size: 40px;
        }
        > span {
          font-size: 18px;
        }
      }
    }
    &.after-animate {
      .info-company__box {
        margin-top: -85px;
      }
    }
  }

  .heritage-home {
    .slide-info-text {
      padding-top: 80px;
      .item-text {
        &__desc {
          width: 750px;
          h6 {
            margin-bottom: 10px;
            font-size: 15px;
          }
          p {
            font-size: 18px;
          }
          h3 {
            line-height: 1.2;
          }
        }
        &__years {
          font-size: 124px;
          text-align: left;
          span {
            font-size: 48px;
            margin-bottom: -24px;
          }
        }
      }
    }
    .inner-slider-heritage{
      .slide-info-image {
        padding-top: 20px;
        .list-heritage {
          .slick-slide {
            width: 740px;
          }
        }
        .slick-arrow {
          right: 40px;
          &.slick-prev {
            left: -20px;
            right: auto;
          }
        }
      }
    }
  }

  .home-area {
    .globe-wrap {
      figure {
        height: 600px;
      }
    }
    .pin-wrap {
      .pin__dot {
        transform: scale(.8);
      }
    }
  }

  .product-home {
    .image-big {
      width: 400px;
      padding-left: 0px;
    }
    .image-thumb {
      width: 210px;
      &:before,
      &:after {
        left: -175px;
        right: 50px;
      }
      &:after {
        width: 100%;
        right: 200px;
      }
      &__item {
        width: 124px;
        span {
          font-size: 15px;
        }
        .bg-image {
          padding: 15px;
        }
      }
    }
  }
  .comitment-home {
    .half-text {
      &__information {
        width: 330px;
        p {
          font-size: 16px;
        }
      }
    }
  }


}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  .banner-home {
    &__caption {
      .text {
        width: 400px;
      }
      h1,h2 {
        font-size: 50px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .info-company {
    &__box {
      padding: 32px 20px;
      margin: 0 20px;
      &--item {
        padding: 0 32px;
        .count {
          font-size: 36px;
          min-width: 140px;
        }
        > span {
          font-size: 16px;
        }
      }
    }
  }
  .heritage-home {
    .slide-info-text {
      .item-text {
        &__desc {
          width: calc(100% - 320px);
          p {
            font-size: 16px;
          }
        }
        &__years {
          font-size: 100px;
          span {
            font-size: 40px;
            margin-bottom: -20px;
          }
        }
      }
    }
    .inner-slider-heritage {
      .slide-info-image {
        padding-top: 8px;
        .list-heritage {
          padding-top: 0;
          .slick-slide {
            width: 660px;
            margin-right: 24px;
          }
          figure {
            height: auto;
          }
        }
      }
      .progress-indicator {
        margin-top: 30px;
      }
    }
  }
  .product-home {
    .image-thumb {
      width: 180px;
      &__item {
        span {
          font-size: 14px;
        }
      }
    }
    &__text {
      width: 240px;
    }
  }

  .comitment-home {
    .half-text {
      &__information {
        width: 240px;
        margin-right: 40px;
      }
    }
  }

  .home-area {
    padding: 80px 0 180px;
    margin-bottom: -220px;
    .pin-wrap {
      width: 900px;
      transform: scale(1);
      top: -5px;
      .pin {
        &__desc {
          &--title {
            font-size: 15px;
          }
          .area-coverage{
            left: 58%;
            &.lf {
              right: 58%;
            }
          }
        }
      }
    }
    .globe-wrap {
      figure {
        height: 445px;
        width: 1000px;
        margin: auto;
      }
    }
  }





  .contact-home {
    margin-bottom: -160px;
    figure {
      height: 400px;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
    &__text {
      width: 600px;
    }
    &.with-bg {
      &:before {
        height: 60%;
      }
    }
  }

}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .banner-home {
    &__caption {
      h1,h2 {
        font-size: 40px;
      }
    }
  }

  .info-company {
    &__box {
      &--item {
        max-width: 28%;
        padding: 0 24px;
        .count {
          min-width: 0;
          font-size: 24px;
        }
        > span {
          font-size: 14px;
        }
      }
    }
  }
  .contact-home {
    figure {
      height: 360px;
    }

    &__text {
      max-width: 600px;
      width: calc(100% - 80px);
    }
  }
  .home-area {
    .text {
      margin-bottom: 60px;
      width: auto;
    }
    .globe-wrap {
      figure {
        display: none;
        &.mobile-globe {
          display: block;
          width: 100%;
          height: auto;
          img {
            min-height: 360px;
          }
        }
      }
      .pin-wrap {
        display: none;
      }
      .list-countries {
        width: calc(100vw - 40px);
        max-width: 500px;
        background: #fff;
        border-radius: 8px;
        margin: auto;
        padding: 20px;
        margin-bottom: 100px;
        display: block;
        h6 {
          font-weight: 600;
          text-align: center;
          font-size: 18px;
          margin-bottom: 12px;
        }
        ul {
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            padding-left: 12px;
            font-size: 14px;
            margin: 0;
            font-weight: 500;
            position: relative;
            margin-right: 6px;
            margin-bottom:6px;
            &:before {
              content: '';
              width: 6px;
              height: 6px;
              background:#E52222;
              position: absolute;
              left: 0;
              top: 7px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .heritage-home {
    .slide-info-text {
      position: relative;
      .slick-track {
          display: flex !important;
      }
      .slick-slide {
        height: inherit !important;
        > div {
          height: 100%;
          .item-text {
            height: 100%;
            display: flex;
            align-items: flex-end;
          }
        }
      }
      .item-text {
        &__desc {
          width: 100%;
          padding-left: 0;
        }
        &__years {
          display: none;
        }
      }
    }
    .inner-slider-heritage {
      .slide-info-image {
        padding-top: 20px;
        .list-heritage { 
          .slick-slide {
            width: 680px;
            margin-right: 24px;
            
            > div {
              height: 100%;
            }
          }
          figure {
            span {
              font-size: 13px;
            }
          }
        }
        .slick-arrow {
          right: 20px;
          &.slick-prev {}
        }
      }
    }
  }
    .product-home {
      &__text {
        width: 100%;
      }
  
      .image-big {
        position: relative;
      }
      .image-thumb {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        margin: 0;
        left: 0;
        &:before {
          display: none;
        }
        &__item {
          margin: 24px 12px 0 !important;
          margin-bottom: 0;
          width: 110px;
          span {
            text-align: center !important;
            margin-top: 0;
            bottom: -30px !important;
          }
          .bg-image {
            padding: 10px;
            figure {
              border-width: 8px;
            }
          }
        }
      }
    }
  .comitment-home {
    min-height: calc(100vh - 64px);
    flex-direction: column-reverse;
    .half-text,
    .half-video {
      width: 100%;
      height: auto;
    }
    .half-text {
      padding: 60px 0;
      &__information {
        width: auto;
        margin: 0 40px;
        
      }
    }
    .half-video {
      height: 480px;
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .banner-home {
    &__caption {
      .text {
        max-width: 400px;
        width: 100%;
      }
      h2,h1 {
        font-size: 36px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  .info-company {
    &__box {
      margin: 0;
      padding: 10px 0;
      &--item {
        width: 50%;
        max-width: 50%;
        padding: 24px 28px;
        &:nth-child(2n){
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .heritage-home {
    left: 0 !important;
    .slide-info-text {
      padding-top: 60px;
      .item-text {
        &__desc {
          h6{
            font-size: 14px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .inner-slider-heritage {
      .slide-info-image {
        margin-right: 0px;
        .list-heritage {
          padding-top: 0;
          .slick-slide {
            width: calc(100vw - 40px);
            margin: 0 8px;
          }
          figure {
            span {
              font-size: 12px;
            }
          }

        }
        .slick-arrow {
          width: 32px;
          height: 32px;
          background-size: 100%;
          top: auto;
          right: 0;
          bottom: -48px;
          &.slick-prev {
            left: 0;
          }
        }
      }
      .progress-indicator {
        height: 3px;
        width: calc(100% - 100px);
        margin: 32px auto ;
        #progress {
          height: 3px;

        }
      }
    }
  }

  .product-home {
    padding: 40px 0;
    .wrapper {
      padding: 0;
      min-height: 0;
    }
    &__text {
      margin-bottom: 40px;
    }
    .image-big {
      max-width: calc(100% - 40px);
    }
    .image-thumb {
      margin-bottom: 40px;
      &__item {
        width: 84px;
        .bg-image {
          padding: 8px;
          figure {
            border-width: 6px;
          }
        }
        span {
          font-size: 12px;
          width: calc(100% + 20px);
          transform: translateX(-50%);
          left: 50%;
        }
      }
    }
  }

  .home-area {
    .text {
      h3{
        margin-bottom: 16px;
      }
      p {
        font-size: 14px;
      }
    }
    .globe-wrap {
      figure.mobile-globe {
        img {
          min-height: 200px;
        }
      }
    }
  }
  .comitment-home {
    .half-text {
      &__information {
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .half-video {
      height: calc(100vw + 40px);
      min-height: 360px;
    }
  }

  .contact-home {
    margin-bottom: -90px;
    figure {
      height: 260px;
    }
  
    &__text {
      width: calc(100% - 40px);
    }
  
    &.with-bg {
      &:before {
        height: 66%;
      }
    }
  }
}