/*=============================================================================================	
    Document   : Stylesheet
    Author     : xxx
==============================================================================================*/
/* milligram
----------------------------------------------------------------------------------------------*/
@import "milligram/milligram";

/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general";
@import "pages/banner-page";
@import "pages/home";
@import "pages/contact";
@import "pages/news";
@import "pages/product";
@import "pages/about";



