.main-container {
  &.about-page{
    padding-top: 0;
  }
}

.list-text-image {
  .text-image-item {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-bottom: 40px;
    .img {
      width: 50%;
      padding: 0 28px;
      figure {
        padding: 32px;
        background: url('../images/content/bg-img-about.png') no-repeat center;
        p {
          font-size: 14px;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
    }
    .description {
      width: 50%;
      padding: 0 28px;
      padding-top: 100px;
      h3 {
        font-size: 35px;
        font-weight: 300;
      }
    }
    &:nth-child(odd){
      .img {
        order: 2;
        -webkit-order: 2;
      }
      .description {
        order:  1;
        -webkit-order: 1;
      }
    }
    &:first-child {
      .img {
        figure {
          margin-top: -40px;
        }
      }
    }
    &.animating-scroll {
      .img {
        position: relative;
        opacity: 0;
        top: 35px;
      }
      .description {
        h3,p {
          position: relative;
          opacity: 0;
          top: 35px;
        }
      }
    }
    &.after-animate {
      .img {
        top: 0;
        opacity: 1;
        transition: all ease-out .4s;
        transition-delay: .4s;
      }
      .description {
        h3,
        p {
          opacity: 1;
          top: 0px;
          transition: all ease-out .4s;
        }
        h3 {transition-delay: .6s;}
        p {transition-delay: .7s;}
      }
    }
    
  }
}

.purpose-value {
  padding: 100px 0;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 40px;
    h3 {
      font-size: 45px;
      font-weight: 300;
    }
  }
  .slider-purpose {
    margin: 0 calc((100vw - var(--wrap) - 18px) / 2 * -1);
    overflow: hidden;
    padding-bottom: 60px;
    .slick-slide {
      margin: 0 24px;
      width: var(--wrap);
      box-sizing: border-box;
    }
    .slide {
      position: relative;
      figure {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: auto;
          left: 0;
          right: 0;
          height: 50%;
          bottom: 0;
          background: linear-gradient(rgb(28, 28, 28) 0%, rgba(26, 26, 26, 0) 100%);
          opacity: 0.8;
          transform: rotate(-180deg);
        }
        img {
          width: 100%;
          display: block;
        }
      }
      .caption-text {
        position: absolute;
        z-index: 2;
        top: auto;
        bottom: 0;
        padding: 0 115px 46px 56px;
        p {
          margin: 0;
          color: var(--color-initial);
        }
        h4 {
          color: var(--color-initial);
        }
      }
    }
    .slick-dots {
      position: absolute;
      display: flex;
      width: var(--wrap);
      margin: 0 auto;
      left: 0;
      right: 0;
      top: auto;
      bottom: 0;
      justify-content: center;
      li {
        margin: 0 6px;
        button {
          font-size: 0;
          padding: 0;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: transparent;
          position: relative;
          border-color: transparent;
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: #747474;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            border-color: var(--color-primary);
            &:after {
              background: var(--color-primary);
            }
          }
        }
      }
    }
  }
}

.nomanbhoy-press {
  padding: 40px 0 0;
  .title {
    text-align: center;
    margin-bottom: 40px;
    h3 {
      font-size: 45px;
      font-weight: 300;
    }
  }
  .slick-list {
    margin: 0 -32px;
  }
  .slider-press {
    .slick-track {
      display: flex !important;
    }
    .slick-slide {
      margin: 0 32px;
      height: inherit !important;
      > div {
        height: 100%;
      }
    }
    .slide {
      background: var(--color-initial);
      height: 100%;
      a {
        &:hover {
          figure {
            img {
              transform: scale(1.05);
              transition: all ease-out .3s;
            }
          }
        }
      }
      figure {
        overflow: hidden;
        img {
          width: 100%;
          display: block;
          transition: all ease-out .3s;
        }
      }
      figcaption {
        padding: 20px;
        padding-bottom: 28px;
        .date-news {
          font-size: 14px;
          display: block;
          margin-bottom: 16px;
          color: #383838;
        }
        p {
          margin: 0;
          font-size: 20px;
          font-family: var(--font-2);
          line-height: 1.3;
          font-weight: 700;
          color: #3C3C3C;
        }
      }
    }
    .slick-arrow {
      width: 44px;
      height: 44px;
      background: #fff url('../images/material/arrow-slide.png') no-repeat center;
      padding: 0;
      border: none;
      border-radius: 22px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      font-size: 0;
      left: auto;
      right: -60px;
      &.slick-prev {
        left: -60px;
        right: auto;
        transform: rotate(-180deg);
      }
    }
  }
  
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .list-text-image {
    .text-image-item {
      .description {
        padding-top: 80px;
        h3 {
          font-size: 30px;
        }
      }
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  .list-text-image {
    .text-image-item {
      .description {
        h3 {
          font-size: 28px;
        }
      }
    }
  }
  .purpose-value {
    padding: 60px 0 40px;
    .title {
      h3 {
        font-size: 36px;
      }
    }
    .slider-purpose {
      margin: 0 -40px;
      padding-bottom: 48px;
      .slick-slide {
        width: calc(100vw - 200px);
        margin: 0 16px;
      }
      .slide {
        figure {
          img {
            min-height: 400px;
            object-fit: cover;
          }
        }
        .caption-text {
          padding: 0 80px 40px 40px;
        }
      }
    }
  }
  .nomanbhoy-press {
    .title {
      h3 {
        font-size: 36px;
      }
    }
    .slick-list {
      margin: 0;
    }
    .slider-press {
      .slick-slide {
        margin: 0 20px;
      }
      .slick-arrow {
        right: 0;
        &.slick-prev {
          left: 0;
        }
      }
      .slide {
        figcaption {
          .date-news {
            font-size: 13px;
          }
        }
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

  .list-text-image {
    .text-image-item {
      width: calc(100% + 20px);
      margin-left: -10px;
      .img {
        width: 100%;
        max-width: 450px;
        padding: 0 10px;
        margin: 0 auto;
        figure {
          padding: 24px;
        }
      }
      .description {
        width: 100%;
        padding: 0 10px;
        padding-top: 40px;
      }
      &:nth-child(odd){
        .img {
          order: 1;
          -webkit-order:1;
        }
        .description {
          order: 2;
          -webkit-order: 2;
        }
      }
    }
  }

  .purpose-value {
    padding: 40px 0;
    .slider-purpose {
      .slick-track {
        display: flex !important;
        
      }
      .slick-slide {
        height: inherit !important;
        width: calc(100vw - 100px);
        >div {
            height: 100%;
          }
      }
      .slide {
        height: 100%;
        background: #171717;
        figure {
          img {
            min-height: 0;
          }
        }
        .caption-text {
          position: relative;
          background: #171717;
          padding-top: 30px;
        }
      }
    }
  }
  .nomanbhoy-press {
    .slider-press {
      .slick-list {
        margin: 0 -12px;
      }
      .slick-arrow {
        right: -8px;
        &.slick-prev {
          left: -8px;
        }
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .list-text-image {
    .text-image-item {
      .img {
        figure {
          padding: 16px;
          p {
            font-size: 12px;
          }
        }
      }
      .description {
        h3 {
          font-size: 20px;
          line-height: 1.4;
        }
      }
    }
  }
  .purpose-value {
    .title {
      h3 {
        font-size: 24px;
      }
    }
    .slider-purpose {
      margin: 0;
      .slick-slide {
        width: calc(100vw - 40px);
        margin: 0 1px;
      }
      .slide {
        figure {
          &:after {
            bottom:  -2px;
          }
        }
        .caption-text {
          padding: 20px 24px 30px;
          h4 {
            margin-bottom: 16px;
          }
        }
      }
      .slick-dots {
        li {
          margin: 0 2px;
          button {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .nomanbhoy-press {
    .title {
      h3 {
        font-size: 24px;
        padding: 0 20px;
      }
    }
    .slider-press {
      padding-bottom: 60px;
      .slick-list {
        margin: 0;
      }
      .slick-slide {
        margin: 0 8px;
      }
      .slick-arrow {
        width: 34px;
        height: 34px;
        background-size: 100%;
      }
      .slide {
        figcaption {
          .date-news {
            font-size: 12px;
          }
          p {
            font-size: 18px;
          }
        }
      }
      
    }
  }
}

@media all and (max-width: 540px) {
  .nomanbhoy-press {
    .slider-press {
      padding-bottom: 60px;
      .slick-slide {
        margin: 0 1px;
      }

      .slick-arrow {
        position: absolute;
        right: calc(50% - 40px);
        bottom: 6px;
        top: auto;
        &.slick-prev {
          left: calc(50% - 40px);
        }
      }

      .slide {
        figcaption {
          .date-news {
            font-size: 12px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}