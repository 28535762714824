header {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  transition: all ease-out .3s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(96,96,96,1) 0%, rgba(38,38,38,0) 100%);
    opacity: .8;
    z-index: 1;
    transition: all ease-out .3s;
  }
  &:after {
    height: 0;
    background: var(--color-initial);
    z-index: 2;
  }
  .wrapper {
    max-width: none;
    margin: 0 90px;
    display: flex;
    justify-content: space-between;
    width: auto;
    align-items: center;
    position: relative;
    z-index: 3;
  }
  .burger {
    display: none;
  }
  .logo {
    width: 145px;
    margin-right: 65px;
    img {
      display: block;
      filter: invert(100%) sepia(100%) saturate(0) hue-rotate(111deg) brightness(113%) contrast(101%);
      transition: all ease-out .25s;
    }
  }
  .main-header {
    .search-mobile {
      display: none;
      form {
        position: relative;
        margin: 0;
    
        input[type="text"] {
          border: 1px solid #dddd;
          padding: 10px 40px 10px 20px;
          border-radius: 8px;
          margin: 0;
        }
    
        button {
          position: absolute;
          right: 6px;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 3;
          cursor: pointer;
          padding: 0 10px;
          height: 32px;
          background: transparent;
          border: none;
    
          img {
            filter: invert(77%) sepia(0%) saturate(89%) hue-rotate(143deg) brightness(119%) contrast(87%);
          }
        }
      }
    }
    nav {
      ul {
        margin: 0;
        display: flex;
        justify-content: center;
        li {
          padding: 0 20px;
          margin: 0;
          a {
            padding: 30px 4px;
            display: block;
            color: var(--color-initial);
            transition: all ease-out .25s;
            text-transform: uppercase;
            font-weight: 500;
            position: relative;
            &:before {
              content: "";
              left: 0;
              right: 0;
              bottom: 16px;
              height: 3px;
              background: #E52222;
              transition: all ease-out .25s;
              width: 0;
              margin: auto;
              position: absolute;
              border-radius: 2px;
            }
            &:hover,
            &.active {
              color: var(--color-initial);
              transition: all ease-out .25s;
              &:before {
                transition: all ease-out .25s;
                width: 100%;
              }
            }
          }
          ul {
            display: none;
          }
        }
      }
    }
  }
  .right-header {
    display: flex;
    justify-content: flex-end;
    &__search {
      margin-left: 32px;
      position: relative;
      > span {
        width: 44px;
        height: 44px;
        display: inline-flex;
        border-radius: 50%;
        background: var(--color-primary);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          display: block;
        }
        &:hover{
          background: var(--color-secondary);
        }
        &.act {
          + .drop-search {
            display: block;
            transition: all ease-out .25s;
          }
        }
      }
      .drop-search {
        position: absolute;
        background: var(--color-initial);
        top: calc(100% + 16px);
        width: 400px;
        left: auto;
        right: 0;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 0 15px -1px rgba($color: #000000, $alpha: .075);
        display: none;
        transition: all ease-out .25s;
        form {
          position: relative;
          margin: 0;
          input[type="text"]{
            border: 1px solid #dddd;
            padding: 10px 40px 10px 20px;
            border-radius: 8px;
            margin: 0;
          }
          button {
            position: absolute;
            right: 6px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 3;
            cursor: pointer;
            padding: 0 10px;
            height: 32px;
            background: transparent;
            border: none;
            img {
              filter: invert(77%) sepia(0%) saturate(89%) hue-rotate(143deg) brightness(119%) contrast(87%);
            }
          }
        }
      }
    }
  }
  &.float {
    transition: all ease-out .3s;
    box-shadow: 0 3px 20px -1px rgba($color: #000000, $alpha: .075);
    &:after {
      height: 100%;
      transition: all ease-out .25s;
      opacity: 1;
    }
    .logo {
      img {
        filter: none;
        transition: all ease-out .25s;
      }
    }
    .main-header {
      nav {
        ul {
          li {
            a {
              padding: 24px 12px;
              color: #0B0B0B;
            }
          }
        }
      }
    }
  }
}


.main-container {
  position: relative;
  padding: 100px 0 130px;
  background: #F5F5F5;
  &.no-pb {
    padding-bottom: 0;
  }
}




footer {
  position: relative;
  display: block;
  .main-footer {
    background: url('../images/material/bg-footer.jpg') no-repeat center ;
    position: relative;
    padding: 300px 0 60px;
    background-size: cover;
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
    &__col {
      width: 40%;
      padding-right: 80px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: var(--color-initial);
      align-items: flex-start;
      h5 {
        width: 100%;
        font-size: 17px;
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .address,
      .contact-info,
      nav {
        width: 50%;
        align-self: flex-start;
        a {
          color: var(--color-initial);
          font-size: 15px;
          transition: all ease-out .25s;
          &:hover {
            transition: all ease-out .25s;
            opacity: .7;
          }
        }
         p{
          line-height: 2;
          margin-bottom: 0;
         }
      }
      .contact-info {
        a {
          display: inline-flex;
          align-items: center;
          margin-bottom: 10px;
          position: relative;
          padding-left: 30px;
          img {
            display: block;
            position: absolute;
            left: 0;
          }
        }
      }
      nav {
        a {
          display: block;
          margin-bottom: 10px;
        }
      }
      .sosmed {
        display: flex;
        li {
          margin-right: 20px;
          a {
            display: block;
            transition: all ease-out .25s;
            top: 0;
            position: relative;
            &:hover {
              transition: all ease-out .25s;
              opacity: .7;
              top: -2px
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &:nth-child(2) {
        width: 37%;
        padding-left: 80px;
      }
      &:nth-child(3) {
        width: 22%;
        padding-right: 0;
        padding-left: 80px;
      }
    }
  }
  .copyright {
    padding: 14px 0;
    p {
      margin: 0;
      text-align: center;
      font-size: 13px;
      color: var(--color-neutral);
    }
  }
  &.not-padding {
    .main-footer {
      padding-top: 80px;
    }
  }
}


.loader {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999999;
	.innerLoad {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		position: relative;
		z-index: 5;
    .logo-load {
      width: 220px;
      img {
        width: 100%;
      }
    }
		.line-load {
			border-radius: 0px;
			height: 7px;
			left: 0;
			position: fixed;
			right: 0;
			top: 0;
			width: 100%;
			z-index: 9;
			&:before,
			span {
				height: 3px;
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				background: var(--color-initial);
				z-index: 1;
				
			}
			span {
				background: var(--color-secondary);
				border-radius: 0px;
				display: block;
				width: 0;
				position: relative;
				z-index: 2;
			}
		}
	}
	.bg-load {
    background: var(--color-initial);
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
	}
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  footer {
    .main-footer {
      &__col {
        padding-right: 50px;
        &:nth-child(2) {
          padding-left: 50px;
        }
        &:nth-child(3) {
          padding-left: 50px;
        }
      }
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  header {
    .wrapper {
      margin: 0 40px;
    }
    .logo {
      width: 124px;
    }
    .main-header {
      nav {
        ul {
          li {
            padding: 0 12px;
            a {
              font-size: 15px;
            }
          }
        }
      }
    }
    .right-header {
      &__search {
        > span {
          height: 40px;
          width: 40px;
          img {
            width: 16px;
          }
        }
      }
    }
  }

  .main-container {
    padding: 60px 0 100px;
  }


  footer {
    &.not-padding {
      .main-footer {
        padding-top: 60px;
      }
    }
    .main-footer {
      padding: 200px 0 40px;
      &__col {
        padding-right: 32px;
        font-size: 14px;
        .address {
          width: 40%;
        }
        .contact-info {
          width: 55%;
          a {
            display: block;
          }
        }
        h5 {
          font-size: 16px;
        }
        .contact-info,
        nav {
          a {
            font-size: 14px;
          }
        }
        &:nth-child(2) {
          padding-left: 32px;
        }

        &:nth-child(3) {
          padding-left: 32px;
        }
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

  header {
    padding: 24px 0;
    .burger {
      display: block;
      width: 24px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      span {
        height: 2px;
        position: absolute;
        width: 100%;
        background: var(--color-initial);
        border-radius: 2px;
        transition: all ease-out .275s;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          width: 70%;
          top: 7px;
        }
        
        &:nth-child(3) {
          top: 14px;
        }
      }
      &.active {
        span{
          transition: all ease-out .275s;
          &:nth-child(1) {
            top: 7px;
            transform: rotate(45deg);
          }
        
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
        
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 7px;
          }
        }
      }
    }
    .logo {
      margin: auto;
      left: 0;
      right: 0;
      position: absolute;
    }
    .main-header {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      background: #fff;
      padding: 30px 40px;
      border-top: 1px solid #eaeaea;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      transition: all ease-out .25s;
      z-index: 1;
      top: 64px;
      .search-mobile {
        display: block;
        opacity: 0;
        top: 30px;
      }
      &.drop-menu {
        display: block;
        transition: all ease-out .25s;
        opacity: 1;
        visibility: visible;
        z-index: 8;
        .search-mobile {
          top: 0;
          opacity: 1;
          transition: all ease-out .4s;
          transition-delay: .3s;
        }
        nav {
          ul {
            li {
              opacity: 1;
              top: 0;
              transition: all ease-out .4s;
              &:nth-child(1){ transition-delay: .4s;}
              &:nth-child(2){transition-delay: .5s;}
              &:nth-child(3){transition-delay: .6s;}
              &:nth-child(4){transition-delay: .7s;}
              &:nth-child(5){transition-delay: .8s;}
            }
          }
        }
      }
      nav {
        margin-top: 60px;
        ul {
          display: block;
          li {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;
            opacity: 0;
            position: relative;
            top: 30px;
            a {
              color: #0B0B0B !important;
              padding: 12px 0 !important;
              &:before {
                bottom: 0;
              }
              &:hover,
              &.active {
                &:before {
                  width: 30px;
                }
              }
            }
          }
        }
      }
    }
    &.float {
      padding: 12px 0;
      .burger {
        span {
          transition: all ease-out .275s;
          background: var(--color-primary);
        }
      }
    }
    .right-header {
      margin-left: auto;
      .button {
        font-size: 0;
        background: var(--color-primary) url('../images/material/ico-contact.svg') no-repeat center;
        width: 40px;
        padding: 0;
        border-radius: 50%;
        &:hover {
          background-color: var(--color-secondary);
        }
      }
      &__search {
        display: none;
      }
    }
  }

  footer {
    .main-footer {
      padding: 200px 0 30px;

      &__col {
        padding-right: 0px;
        width: 100%;

        .address {
          width: 50%;
        }

        .contact-info {
          width: 50%;
          padding-left: 32px;
        }

        &:nth-child(1) {
          margin-bottom: 32px;
        }

        &:nth-child(2) {
          width: 50%;
          padding-left: 0;
        }

        &:nth-child(3) {
          width: 50%;
        }
      }
    }
  }

  
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .loader .innerLoad .logo-load {
    width: 160px;
  }
  header {
    padding: 18px 0;
    .wrapper {
      margin: 0 20px;
    }
    .logo {
      width: 100px;
    }
    .right-header {
      .button {
        height: 32px;
        width: 32px;
      }
    }
    .main-header {
      top: 56px;
      padding: 35px 20px;
      nav {
        ul {
          li {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .main-container {
    padding: 40px 0 64px;
  }

  footer {
    .main-footer {
      padding: 120px 0 40px;

      &__col {
        font-size: 12px;
        h5 {
          font-size: 14px;
        }
        .address {
          padding-right: 20px;
          width: 45%;
        }
        .contact-info {
          padding-left: 0px;
          width: 55%;
        }
        .contact-info,
        nav {
          width: 55%;
          &:nth-of-type(1) {
            width: 45%;
          }
          a {
            font-size: 12px;
          }
        }
        .sosmed {
          margin-bottom: 0;
          li {
            margin-bottom: 0;
            a {
              width: 32px;
            }
          }
        }
        &:nth-child(1) {
          margin-bottom: 24px;
        }
        &:nth-child(2) {
          width: 100%;
          margin-bottom: 24px;
        }
        &:nth-child(3) {
          width: 100%;
          padding-left: 0px;
        }
      }
    }
    .copyright {
      padding: 10px 0;
      p {
        font-size: 12px;
      }
    }
  }
  
}