.news-page {
  padding-bottom: 90px;
  .row {
    width: calc(100% + 70px);
    margin-left: -35px;
    .column {
      padding: 0 35px;
      figure {
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  &.detail {
    padding-top: 0;
    z-index: 3;
    background: transparent;
    &:before {
      content: '';
      position: absolute;
      top: 200px;
      left: 0;
      right: 0;
      bottom: 0;
      background: #f5f5f5;
      z-index: -1;
    }
    .wrap-detail {
      .row {
        width: calc(100% + 32px);
        margin-left: -16px;
        .column {
          padding: 0 16px;
        }
      }
    }
  }
}
.item-news {
  display: flex;
  .date-news {
    color: #383838;
    margin-bottom: 20px;
  }
  figure {
    position: relative;
    overflow: hidden;
    a {
      display: block;
    }
    img {
      transition: all ease-out .35s;
      display: block;
    }
  }
  figcaption {
    h4 {
      font-size: 23px;
      font-weight: 700;
      color: #3c3c3c;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
  &:hover {
    figure {
      img {
        transition: all ease-out .35s;
        transform: scale(1.075);
      }
    }
  }
}
.news-highlight {
  position: relative;
  margin-bottom: 70px;
  &__big {
    margin-bottom: 0 !important;
    .item-news {
      display: flex;
      background: var(--color-initial);
      figure {
        width: 50%;
      }
      figcaption {
        width: 50%;
        padding: 36px;
        align-self: center;
        .date-news {
          margin-bottom: 40px;
        }
        h4 {
          font-size: 25px;
          margin-bottom: 20px;
          a {
            color: #3C3C3C;
          }
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
      }
    }
  }
  &__list {
    margin-bottom: 0 !important;
    > h4 {
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 32px;
    }
    .item-news {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #B2B2B2;
      figure {
        width: 160px;
        img {
          height: 100%;
          object-fit: cover;
          max-height: 110px;
          width: 100%;
        }
      }
      figcaption {
        width: calc(100% - 160px);
        padding-left: 24px;
        align-self: center;
        p {
          margin: 0;
          color: #3C3C3C;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &.animating-scroll {
    .news-highlight__big {
      opacity: 0;
      top: 30px;
      position: relative;
    }
    .news-highlight__list {
      .item-news {
        opacity: 0;
        top: 30px;
        position: relative;
      }
    }
  }
  &.after-animate {
    .news-highlight__big {
      top: 0;
      transition: all ease-out .4s;
      opacity: 1;
      transition-delay: .3s;
    }
    .news-highlight__list {
      .item-news {
        opacity: 1;
        top: 0px;
        transition: all ease-out .4s;
        &:nth-child(1) {transition-delay: .3s;}
        &:nth-child(2) {transition-delay: .4s;}
        &:nth-child(3) {transition-delay: .5s;}
        &:nth-child(4) {transition-delay: .6s;}
      }
    }
  }
}
.news-listing {
  .item-news {
    margin-bottom: 52px;
    figcaption {
      padding-top: 24px;
      h4 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }
  }
}
.wrap-btn {
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 60px;
}
.journey-content {
  padding: 100px 0;
  background: url('../images/content/banner-journey.jpg') no-repeat center;
  background-size: cover;
  .wrapper {
    text-align: center;
    h3 {
      font-weight: 300;
      color: var(--color-initial);
    }
  }
}

.wrap-detail {
  > figure {
    position: relative;
    margin-top: -200px;
    margin-bottom: 60px;
  }
  h3 {
    font-size: 40px;
    margin-bottom: 40px;
  }
}
.text-detail-news {
  padding: 0 100px;
  .row {
    margin: 50px 0 30px;
    position: relative;
    .column {
      margin-bottom: 0;
    }
  }
  .back-detail {
    padding-top: 40px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #BCBCBC;
    .button {
      min-width: 200px;
    }
    .share-sosmed {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      label {
        margin: 0;
        text-transform: uppercase;
        margin-right: 12px;
      }
      ul {
        margin: 0;
        display: flex;
        li {
          display: block;
          margin: 0;
          padding-left: 0;
          &:before {
            display: none;
          }
          a {
            display: flex;
            align-items: center;
            color: var(--color-neutral);
            transition: all ease-out .25s;
            img {
              display: block;
              margin-right: 8px;
              position: relative;
              transition: all ease-out .25s;
              top: 0;
            }
            &:hover {
              transition: all ease-out .25s;
              color: var(--color-secondary);
              img {
                top: -2px;
                transition: all ease-out .25s;
              }
            }
          }
        }
      }
    }
  }
}

.related-news {
  background: #EFEFEF;
  padding: 90px 0;
  &__listing {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 40px);
    margin-left: -20px;
    .item-news.column {
      display: flex !important;
      padding: 0 20px;
      padding-right: 30px;
      align-items: center;
      position: relative;
      &.column-33 {
        flex: none;
        max-width: none;
      }
      &:before {
        content: '';
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: auto;
        right: 20px;
        background: #C9C9C9;
        height: 80%;
        margin: auto;
      }
      figure {
        width: 115px;
      }
      figcaption {
        width: calc(100% - 115px);
        padding-left: 28px;
        .date-news {
          margin-bottom: 8px;
        }
        p {
          margin: 0;
          font-weight: 700;
          color: var(--color-neutral);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
    .slick-arrow {
      width: 36px;
      height: 36px;
      background: #fff url('../images/material/arrow-slide.png') no-repeat center;
      padding: 0;
      border: none;
      border-radius: 22px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;
      font-size: 0;
      left: auto;
      right: -30px;
      background-size: 100%;

      &.slick-prev {
        left: -30px;
        right: auto;
        transform: rotate(-180deg);
      }
    }
  }
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .news-page {
    .row {
      width: calc(100% + 48px);
      margin-left: -24px;
      .column {
        padding: 0 24px;
      }
    }
  }
  .news-highlight {
    &__big {
      .item-news {
        figure {
          a {
            height: 100%;
            display: block;
            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    &__list {
      .item-news {
        padding-bottom: 24px;
        margin-bottom: 24px;
        figure {
          width: 140px;
          img {
            max-height: 90px;
          }
        }
        figcaption {
          width: calc(100% - 140px);
        }
      }
    }
  }
  .wrap-detail {
    h3 {
      font-size: 36px;
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {

  .news-page {
    .row {
      width: calc(100% + 24px);
      margin-left: -12px;
      .column {
        padding: 0 12px;
      }
    }
    &.detail {
      &:before {
        top: 140px;
      }
    }
  }
  .news-highlight {
    &__big {
      .item-news {
        figcaption {
          .date-news {
            margin-bottom: 18px;
            font-size: 14px;
          }
          h4 {
            font-size: 20px;
              line-height: 1.3;
            a {
              font-size: 20px;
              line-height: 1.2;
            }
          }
        }
      }
    }
    &__list {
      .item-news {
        figure {
          width: 120px;
          height: 82px;
        }
        figcaption {
          width: calc(100% - 120px);
          p {
            font-size: 14px;
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
  .news-listing {
    .item-news {
      figcaption {
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .item-news {
    .date-news {
      font-size: 14px;
    }
  }
  .wrap-btn {
    padding-top: 40px;
  }

  .wrap-detail {
    > figure {
      margin-top: -140px;
      margin-bottom: 40px;
    }
    h3 {
      font-size: 30px;
    }
  }
  .text-detail-news {
    padding: 0 60px;
    .back-detail {
      .button {
        min-width: 140px;
      }
      .share-sosmed {
        ul {
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .related-news {
    padding: 60px 0;
    &__listing {
      padding-bottom: 60px;
      .item-news.column {
        padding-right: 16px;
      }
      .slick-arrow {
        top: auto;
        right: calc(50% - 40px);
        &.slick-prev {
          left: calc(50% - 40px);
        }
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .news-page {
    .row {
      flex-wrap: wrap;
      .news-highlight__big,
      .news-highlight__list {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    &.detail {
      padding-bottom: 60px;
    }
  }
  .news-highlight {
    &__big {
      margin-bottom: 32px !important;
    }
  }
  .news-listing {
    .item-news {
      &.column-33 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .text-detail-news {
    padding: 0;
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .item-news {
    .date-news {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }
  .news-listing {
    flex-direction: row;
    .item-news {
      figcaption {
        h4 {
          font-size: 18px;
        }
      }
    }
  }
  .news-highlight {
    &__big {
      .item-news {
        figcaption {
          padding: 24px;
          .date-news {
            font-size: 12px;
          }
          h4 {
            font-size: 18px;
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .related-news {
    padding-top: 30px;
    .wrapper {
      > h3 {
        text-align: center;
      }
    }
    &__listing {
      display: block;
      margin-left: 0;
      padding-bottom: 42px;
      width: 100%;
      .item-news.column {
        padding: 0;
        padding-right: 20px;
      }
      .slick-arrow {
        width: 30px;
        height: 30px;
        right: calc(50% - 34px);
        &.slick-prev{ 
          left: calc(50% - 34px);
        }
      }
    }
  }
  .text-detail-news {
    .row {
      margin: 28px 0 0;
    }
    .back-detail {
      .share-sosmed {
        ul {
          li {
            a {
              font-size: 0;
            }
          }
        }
      }
    }
  }
  .wrap-detail {
    h3 {
      font-size: 24px;
      margin-bottom: 24px;
    }
    > figure {
      margin-top: -20%;
      img {
        min-height: 150px;
        object-fit: cover;
        width: 100%;
      }
    }
  }
  .news-page {
    &.detail {
      .wrap-detail {
        .row {
          width: calc(100% + 20px);
          margin-left: -10px;
          .column {
            padding: 0 10px;
            &.column-50 {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 20px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 550px) {
  .news-highlight {
    &__big {
      .item-news {
        flex-wrap: wrap;
        figure {
          width: 100%;
        }
        figcaption {
          padding-top: 32px;
          width: 100%;
        }
      }
    }
  }
  .news-listing {
    .item-news {
      &.column-33 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}