.banner-page {
  position: relative;
  &.background {
    figure {
      height: 610px;
      background: rgb(114,150,81);
      background: linear-gradient(180deg, rgba(114,150,81,1) 0%, rgba(67,96,40,1) 100%);
      &:after {
        display: none;
      }
    }
  }
  &.small {
    figure {
      height: 450px;
    }
  }
  &.detail-news {
    z-index: 2;
    .banner-page__caption {
      top: 40%;
      transform: translateY(-40%);
      h1 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  figure {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(25,25,25,.7);
    }
    img {
      display: block;
    }
  }
  &__caption {
    transform: translateY(-60%);
    top: 60%;
    position: absolute;
    width: 1000px;
    margin: 0 auto;
    left: 0;
    right: 0;
    .wrapper {
      width: auto;
    }
    .text {
      text-align: center;
      
    }
    .date-news {
      display: block;
      color: var(--color-initial);
      margin-bottom: 32px;
    }
    h2 {
      color: var(--color-initial);
      font-size: 60px;
      line-height: 1;
    }
    h3,h1 {
      font-size: 45px;
      color: var(--color-initial);
      line-height: 1.2;
    }
    p {
      color: var(--color-initial);
      margin-bottom: 0;
      margin-top: 35px;
    }
  }
  &.animating-scroll {
    overflow: hidden;
    figure {
      img {
        transform: scale(1.05);
        transition: all ease-out .4s;
        opacity: 0;
      }
    }
    .banner-page__caption {
      .text {
        h2,h1,p,.date-news {
          opacity: 0;
          position: relative;
          top: 30px;
        }
         
      }
    }
    
  }
  &.after-animate {
    figure {
        img {
          transform: scale(1);
          transition: all ease-out .4s;
          transition-delay: .2s;
          opacity: 1;
        }
      }
    .banner-page__caption {
      .text {
        h2,h1,p,.date-news {
          opacity: 1;
          top: 0px;
          transition: all ease-out .4s;
          transition-delay: .3s;
        }
        p {
          transition-delay: .4s;
        }
      }
    }
  }
  &.detail-news {
    &.after-animate {
      .banner-page__caption {
        .text {
          .date-news {
            h1,
            h2 {
              transition-delay: .4s;
            }
          }
        }
      }
    }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .banner-page {
    &__caption {
      h2 {
        font-size: 54px;
      }
      h3,h1 {
        font-size: 38px;
        margin-bottom: 8px;
      }
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  .banner-page {
    figure {
      height: 460px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &__caption {
      width: 800px;
      h2 {
        font-size: 40px;
        line-height: 1.2;
      }
    }
    &.background {
      figure {
        height: 540px;
      }
    }
    &.small {
      figure {
        height: 400px;
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .banner-page {
    figure {
      img {
        object-position: 75%;
      }
    }
    &__caption {
      width: 100%;
      max-width: 640px;
      h2 {
        font-size: 32px;
      }
      .date-news {
        font-size: 12px;
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .banner-page {
    figure {
      height: 400px;
    }
    &__caption {
      width: 100%;
      max-width: 640px;

      h2 {
        font-size: 26px;
        line-height: 1.3;
        margin-bottom: 12px;
      }
      h1,h3 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
        margin-top: 16px;
      }
      .date-news {
        margin-bottom: 20px;
      }
    }

    &.background {
      figure {
        height: 460px;
      }
    }
    &.small {
      .banner-page__caption {
        top: 50%;
        transform: translateY(-50%);
      }
      figure {
        height: 360px;
      }
    }
    &.detail-news {
      figure {
        height: 80vw;
        max-height: 400px;
      }
      .banner-page__caption {
        top: 47%;
        transform: translateY(-47%);
      }
    }
    &.product-banner {
      figure {
        height: 460px;
      }
    }
  }
}