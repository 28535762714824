.office-location {
  padding-top: 60px;
  .text {
    width: 1180px;
    text-align: center;
    margin: 0 auto 60px;
    
    h3 {
      font-weight: 300;
    }
  }
  .iframe-map {
    position: relative;
    margin-bottom: -240px;
    z-index: 2;
    iframe {
      height: 700px;
      display: block;
    }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .office-location {
    .iframe-map {
      iframe {
        height: 500px;
      }
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  .office-location {
    padding-top: 40px;
    .text {
      width: 800px;
    }
    .iframe-map {
      margin-bottom: -150px;
      iframe {
        height: 420px;
      }
    }
  }
  
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .office-location {
      padding-top: 20px;
  
      .text {
        max-width: 600px;
        width: 100%;
        margin-bottom: 32px;
      }
  
      .iframe-map {
        margin-bottom: -90px;
  
        iframe {
          height: 300px;
        }
      }
    }
}