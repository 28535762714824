.product-wrap-tab {
  position: relative;
  &__nav {
    width: 50%;
    margin-left: 50%;
    padding-left: 40px;
    padding-bottom: 30px;
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    .mobile-trigger {
      display: none;
    }
    ul {
      display: flex;
      margin-bottom: 32px;
      li {
        margin: 0;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        a {
          padding: 0 0 10px;
          display: block;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: auto;
            right: 0;
            background: var(--color-primary);
            height: 2px;
            top: auto;
            bottom: 0;
            width: 0;
            transition: all ease-out .25s;
          }
          &:hover,
          &.active {
            &::before {
              width: 100%;
              transition: all ease-out .25s;
              right: auto;
            }
          }
        }
      }
    }
    .search-product {
      position: relative;
      width: 100%;
      max-width: 560px;
      form {
        position: relative;
        margin: 0;
        input {
          border: 1px solid #D1D1D1;
          background: var(--color-initial);
          padding: 13px 20px;
          border-radius: 23px;
          padding-right: 50px;
        }
        button {
         position: absolute;
         right: 10px; 
         z-index: 3;
         width: 40px;
         top: 0;
         bottom: 0;
         left: auto;
         height: auto;
         padding: 0;
         background: url('../images/material/ico-search-2.svg') no-repeat center;
         border: none;
        }
      }
    }
  }
}

.desc-product-list {
  position: relative;
  &:before {
    display: none;
  }
  .item-desc-product {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    &.active {
      display: block;
      position: relative;
      opacity: 1;
    }
    .wrap-desc-detail {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 80px;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transition: all ease-out .35s;
      top: 0;
      left: 0;
      right: 0;
      &.active {
        display: flex;
        opacity: 1;
        position: relative;
        transition: all ease-out .35s;
        visibility: visible;
        .image-big-product {
          transition: all ease-out .5s;
          opacity: 1;
          transform: scale(1) rotate(0);
          transition-delay: .15s;
          
        }
      }
      .wrap-big-image {
        width: 50%;
        padding-right: 40px;
        position: relative;
        align-self: flex-start;
        &:before {
          height: 100%;
          top: 0px;
          bottom: 0;
          margin: 0;
          width: 100%;
          position: absolute;
          left: calc((100vw - var(--wrap)) / 2 * -1);
          right: 0px;
          background: rgb(180, 0, 0);
          background: linear-gradient(90deg, rgba(180, 0, 0, 1) 32%, rgba(93, 2, 4, 1) 100%);
          z-index: 0;
          content: "";
          border-radius: 0 47% 47% 0;
        }
      }
      .image-big-product {
        width: 100%;
        transition: all ease-out .5s;
        opacity: 0;
        transform: scale(.7)  rotate(-10deg);
        position: relative;
        img {
          display: block;
          width: 100%;
          border-radius: 50%;
          box-shadow: 0 5px 45px rgba(0, 0, 0, 0.25);
        }
        
      }
      .desc-image-product {
        width: 50%;
        padding-left: 40px;
        padding-top: 150px;
        h3 {
          font-size: 40px;
          font-weight: 300;
          color: #262626;
          margin-bottom: 4px;
        }
        .green {
          font-size: 15px;
          font-style: italic;
          color: var(--color-primary);
          display: block;
          margin-bottom: 8px;
        }
        .from {
          font-size: 13px;
          color: #1C1C1C;
          opacity: .7;
          margin-bottom: 20px;
          display: block;
        }
        .button {
          margin-top: 24px;
          display: inline-flex;
          min-width: 180px;
        }
      }
    }
  }
}


.thumb-category-slide {
  width: 100%;
  .slick-slide {
    margin-right: 24px;
  }
  .slick-arrow {
    width: 44px;
    height: 44px;
    background: url('../images/material/arrow-slide.png') no-repeat center;
    padding: 0;
    border: none;
    border-radius: 22px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    font-size: 0;
    left: auto;
    right: -60px;
    &.slick-prev {
      left: -60px;
      right: auto;
      transform: rotate(-180deg);
    }
  }
  .item-category {
    display: flex !important;
    padding: 20px;
    align-items: flex-start;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 24px;
    figure {
      width: 94px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
      img {
        width: 100%;
        display: block;
      }
    }
    figcaption {
      width: calc(100% - 94px);
      align-self: center;
      padding-left: 20px;
      h4 {
        font-size: 20px;
        margin-bottom: 8px;
      }
      p {
        font-size: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        margin: 0;
      }
      
    }
    &.active {
      background: var(--color-initial);
      figcaption {
        h4 {
          font-weight: 700;
        }
      }
    }
  }
}
.certificate-product {
  padding-top: 100px;
  .row {
    align-items: center;
    .text-certificate {
      width: calc(100% - 520px);
      padding-right: 100px;
      .text {
        h3 {
          font-size: 40px;
          font-weight: 300;
        }
        p {
          a {
            color: #234CBC;
            text-decoration: underline;
            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }
    .img-certificate {
      width: 520px;
      text-align: center;
      padding-right: 100px;
      figure {
        img {
          display: block;
        }
      }
      strong {
        display: block;
        margin-top: 32px;
        font-size: 20px;
        color: #3C3C3C;
      }
    }
  }
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .product-wrap-tab {
    &__nav {
      ul {
        li {
          margin-right: 34px;
        }
      }
    }
  }
  .thumb-category-slide {
    .item-category {
      padding: 16px;
      figure {
        width: 84px;
      }
      figcaption {
        width: calc(100% - 84px);
        padding-left: 16px;
        h4 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media all and (max-width: 1400px) {
  .desc-product-list {
    
    .wrap-big-image {
      &:before {
          width: calc((var(--wrap) / 2) - 0px);
        }
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
  .product-wrap-tab {
    &__nav {
      position: relative;
      margin-left: 0;
      width: 100%;
      padding-left: 0;
      ul {
        justify-content: center;
        li {
          a {
            padding-bottom: 6px;
          }
        }
      }
      .search-product {
        margin: auto;
      }
    }
  }
  .desc-product-list {
    .item-desc-product {
      .wrap-desc-detail {
        .wrap-big-image {
          &:before {
            left: -40px;
            width: calc(100% - 80px);
            height: 95%;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }
        .image-big-product {
          padding-right: 32px;
        }
        .desc-image-product {
          padding-top: 10px;
          padding-left: 32px;
        }
      }
    }
  }
  .thumb-category-slide {
    .slick-slide {
      margin: 0 10px;
    }
    .slick-arrow {
      width: 36px;
      height: 36px;
      background-size: 100%;
      background-color: #fff;
      right: -20px;
      &.slick-prev {
        left: -20px;
      }
    }
    .item-category {
      figure {
        width: 74px;
      }
      figcaption {
        width: calc(100% - 74px);
        h4 {
          font-size: 16px;
        }
      }
    }
  }
  .certificate-product {
    padding-top: 60px;
    .row {
      .img-certificate {
        width: 300px;
        padding-right: 20px;
        strong {
          font-size: 16px;
          margin-top: 20px;
        }
      }
      .text-certificate {
        padding-right: 50px;
        .text {
          h3 {
            font-size: 32px;
          }
        }
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .desc-product-list {


    .item-desc-product {
      .wrap-desc-detail {
        margin-bottom: 60px;
        .wrap-big-image {
          margin: auto;
          order: 2;
          -webkit-order: 2;
          width: 100%;
          padding-right: 0px;
          &:before {
            width: 80%;
          }
        }
        .image-big-product {
          padding-right: 0px;
          width: 100%;
          margin: auto;
          max-width: 480px;
        }

        .desc-image-product {
          padding-top: 10px;
          padding-left: 0px;
          width: 100%;
          order: 1;
          -webkit-order: 1;
          padding-bottom: 60px;
          h3 {
            font-size: 32px;
          }
        }
      }
    }
  }
  .thumb-category-slide {
    .item-category {
      figure {
        width: 60px;
      }

      figcaption {
        width: calc(100% - 60px);
        p {
          font-size: 13px;
        }
      }
    }
  }
  .certificate-product {
    padding-top: 60px;

    .row {
      .img-certificate {
        width: 100%;
        max-width: 320px;
        padding-right: 10px;

        strong {
          font-size: 16px;
          margin-top: 20px;
        }
      }

      .text-certificate {
        padding-right: 10px;
        margin-bottom: 20px;
        width: 100%;
        .text {
          h3 {
            font-size: 28px;
          }
        }
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .product-wrap-tab {
    &__nav {
      .mobile-trigger {
        display: block;
        padding: 8px 20px;
        padding-right: 30px;
        background: #fff;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid #d1d1d1;
        border-radius: 18px;
        
        position: relative;
        &:before {
          width: 16px;
          height: 16px;
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          margin: auto;
          left: auto;
          right: 16px;
          background: url('../images/material/arrow-menu.svg') no-repeat center;
        }
      }
      ul {
        display: none;
        padding: 20px;
        background: #fff;
        border-radius: 16px;
        border: 1px solid #d1d1d1;
        position: absolute;
        margin: 0;
        left: 0;
        right: 0;
        z-index: 4;
        li {
          margin-bottom: 8px;
          a {
            font-size: 14px;
            &:before {
              left: 0;
              right: auto;
            }
            &.active {
              &:before {
                width: 30px;
                left: 0;
                right: auto;
              }
            }
          }
        }
      }
      .search-product {
        margin-right: 0;
        max-width: 100%;
        margin-top: 12px;
        form {
          input {
            padding: 8px 20px;
            padding-right: 40px;
            font-size: 14px;
          }
          button {
            width: 34px;
          }
        }
      }
    }
  }
  .desc-product-list {
    &:before {
      max-height: 350px;
      display: none;
      bottom: 175px;
    }
    .item-desc-product {
      .wrap-desc-detail {
        margin-bottom: 40px;
      }
    }
  }
  .thumb-category-slide {
    padding-bottom: 50px;
    .item-category {
      padding: 12px;
      figure {
        width: 54px;
      }
      figcaption {
        width: calc(100% - 54px);
        padding-left: 8px;
        h4 {
          font-size: 14px;
          margin-bottom: 4px;
        }
        p {
          font-size: 12px;
        }
      }
    }
    .slick-list {
      margin-right: -20px;
    }
    .slick-slide {
      width: 194px;
      margin: 0 2px;
    }
    .slick-arrow {
      width: 32px;
      height: 32px;
      right: calc(50% - 36px);
      top: auto;
      bottom: 0;
      &.slick-prev {
        left: calc(50% - 36px);
      }
    }
  }
  .certificate-product {
    .row {
      .text-certificate {
        .text {
          h3 {
            font-size: 24px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}